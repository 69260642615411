import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  name: 'SearchBox',
})
export default class SearchBox extends Vue {
  @Prop()
  public placeholder!: string;

  /* Internal state */
  public search: string = '';

  /* Clears search string out and emits an event */
  clearSearch() {
    this.search = '';
    this.$emit('clear');
  }

  /* Emits the search event to let parent component handle the logic */
  triggerSearch() {
    this.$emit('search', this.search);
  }
}
